import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SubHero from '../components/SubHero'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Slider from 'react-slick'

var settings = {
  dots: true,
  infinite: true,
  speed: 1500,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  centerMode: true,
  autoplay: true,
  arrows: false,
  cssEase: 'ease',
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <i class="fas fa-arrow-right" />
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <i class="fas fa-arrow-left" />
    </div>
  )
}

class Project extends Component {
  render() {
    const {
      title,
      projectLink,
      type,
      content,
      color1,
      color2,
      color3,
      color4,
      isBlock1Mobile,
      isBlock2Mobile,
      isBlock3Mobile,
      block1,
      block1Title,
      block2,
      block2Title,
      block3,
      block3Title,
      metaDescription,
    } = this.props.data.contentfulProject

    let colorItem1,
      colorItem2,
      colorItem3,
      colorItem4,
      PROJECTLINK,
      BLOCK1,
      BLOCK2,
      BLOCK3,
      typography,
      logo

    if (color1) {
      colorItem1 = (
        <div
          className={
            color1 == 'ffffff'
              ? 'ng-color-swatch ng-white-color-swatch'
              : 'ng-color-swatch'
          }
          style={{ background: '#' + color1 }}
        />
      )
    }
    if (color2) {
      colorItem2 = (
        <div
          className={
            color2 == 'ffffff'
              ? 'ng-color-swatch ng-white-color-swatch'
              : 'ng-color-swatch'
          }
          style={{ background: '#' + color2 }}
        />
      )
    }
    if (color3) {
      colorItem3 = (
        <div
          className={
            color3 == 'ffffff'
              ? 'ng-color-swatch ng-white-color-swatch'
              : 'ng-color-swatch'
          }
          style={{ background: '#' + color3 }}
        />
      )
    }
    if (color4) {
      colorItem4 = (
        <div
          className={
            color4 == 'ffffff'
              ? 'ng-color-swatch ng-white-color-swatch'
              : 'ng-color-swatch'
          }
          style={{ background: '#' + color4 }}
        />
      )
    }
    if (projectLink != 'null') {
      PROJECTLINK = (
        <div className=" ng-project-link">
          <a href={projectLink}>Visit Project</a>
        </div>
      )
    } else {
      PROJECTLINK = (
        <div className=" ng-project-link">
          <a>Project Not Public Yet</a>
        </div>
      )
    }
    if (block1Title != 'null') {
      let block_1_items = []
      for (let i = 0; i < block1.length; i++) {
        block_1_items.push(
          <div className="ng-project-slider-item" key={i}>
            <img src={block1[i].resolutions.src} />
          </div>
        )
      }
      BLOCK1 = (
        <div className="ng-project-block-container">
          <h2>{block1Title}</h2>
          <Slider
            {...settings}
            slidesToShow={isBlock1Mobile ? 3 : 1}
            centerPadding={isBlock1Mobile ? '20%' : '10%'}
          >
            {block_1_items}
          </Slider>
        </div>
      )
    }
    if (block2Title != 'null') {
      let block_2_items = []
      for (let i = 0; i < block2.length; i++) {
        block_2_items.push(
          <div className="ng-project-slider-item" key={i}>
            <img src={block2[i].resolutions.src} />
          </div>
        )
      }
      BLOCK2 = (
        <div className="ng-project-block-container">
          <h2>{block2Title}</h2>
          <Slider
            {...settings}
            slidesToShow={isBlock2Mobile ? 3 : 1}
            centerPadding={isBlock2Mobile ? '20%' : '10%'}
          >
            {block_2_items}
          </Slider>
        </div>
      )
    }
    if (block3Title != 'null') {
      let block_3_items = []
      for (let i = 0; i < block3.length; i++) {
        block_3_items.push(
          <div className="ng-project-slider-item" key={i}>
            <img src={block3[i].resolutions.src} />
          </div>
        )
      }
      BLOCK3 = (
        <div className="ng-project-block-container">
          <h2>{block3Title}</h2>
          <Slider
            {...settings}
            slidesToShow={isBlock3Mobile ? 3 : 1}
            centerPadding={isBlock3Mobile ? '20%' : '10%'}
          >
            {block_3_items}
          </Slider>
        </div>
      )
    }

    if (title == 'TownWave') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242651/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/TownWave/logo.svg"
          alt="website mobile app development"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242651/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/TownWave/typography.svg'
    } else if (title == 'Pageify') {
      logo = <h2>{title}</h2>
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242657/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Pageify/typography.svg'
    } else if (title == 'Skin Beautiful MD') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242654/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Skin_Beautiful_MD/logo.png"
          alt="mobile app development website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242644/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Skin_Beautiful_MD/typography.svg'
    } else if (title == 'Sure Pick Sports') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242652/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Sure_Pick_Sports/logo.png"
          alt="mobile app development website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242648/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Sure_Pick_Sports/typography.svg'
    } else if (title == 'Node4Rent') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242654/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Node4Rent/logo.png"
          alt="mobile app development website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242655/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Node4Rent/typography.svg'
    } else if (title == 'Icon Machine Shop') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242645/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Icon_Machine_Shop/logo.png"
          alt="mobile app development website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242644/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Icon_Machine_Shop/typography.svg'
    } else if (title == 'My Patient Express') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242659/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/My_Patient_Express/logo.png"
          alt="mobile app development website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242658/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/My_Patient_Express/typography.svg'
    } else if (title == 'HDTV | Home Design TV') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242623/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/HDTV/logo.svg"
          alt="mobile app development website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242658/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/My_Patient_Express/typography.svg'
    } else if (title == 'VetGraft') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242623/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/VetGraft/logo.svg"
          alt="mobile app development website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242618/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/VetGraft/typography.svg'
    } else if (title == 'Mirra Skincare') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242654/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Mirra_Skincare/logo.svg"
          alt="web application website"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242656/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Mirra_Skincare/typography.svg'
    } else if (title == 'Grand Car Community') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242643/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Grand_Car_Community/logo.png"
          alt="mobile app development"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242638/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Grand_Car_Community/typography.svg'
    } else if (title == 'Braxley Bands') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242626/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Braxley_Bands/logo.svg"
          alt="client logo"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242629/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/Braxley_Bands/typography.svg'
    } else if (title == 'DIVIT') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242647/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/DIVIT/logo.svg"
          alt="web design"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242647/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/DIVIT/typography.svg'
    } else if (title == 'First Due On Demand') {
      logo = (
        <img
          src="https://res.cloudinary.com/tylerholden/image/upload/v1577726181/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/First_Due_On_Demand/logo.png"
          alt="web design"
        />
      )
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242658/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/My_Patient_Express/typography.svg'
    } else if (title == 'NoVaxMandate.org') {
      logo = (
        <img
          src="https://novax.us-southeast-1.linodeobjects.com/linode/patriotworkforcer.svg"
          alt="NoVaxMandate"
        />)
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242658/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/My_Patient_Express/typography.svg'
    } else {
      logo = <h3>{title}</h3>
      typography =
        'https://res.cloudinary.com/tylerholden/image/upload/v1557242658/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/projects/My_Patient_Express/typography.svg'
    }

    return (
      <Layout>
        <Helmet
          title={`${title} Project | NEXTGEN Code Company`}
          meta={[{ name: 'description', content: { metaDescription } }]}
        />
        <SubHero
          title={title}
          subtitle={type}
          defaultBackText={'Projects'}
          defaultBackURL={'/projects'}
          classes
        />
        <section className="section">
          <div className="container">
            <div className="column ng-project-logo">{logo}</div>
            <div className="ng-project-stylesheet-container">
              <div className="ng-project-style-column">
                <h3>Colors</h3>
                <div className="ng-project-style-inner">
                  {colorItem1}
                  {colorItem2}
                  {colorItem3}
                  {colorItem4}
                </div>
              </div>
              <div className="ng-project-style-first-column" />
              <div className="ng-project-style-column">
                <h3>Typography</h3>
                <div className="ng-project-style-inner">
                  <img
                    src={typography}
                    alt="web development, SEO and mobile apps"
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="ng-project-type-container">
                <h2>{type}</h2>
              </div>
              <div className="ng-project-content-container">
                <span
                  dangerouslySetInnerHTML={{
                    __html: content.childMarkdownRemark.html,
                  }}
                />
                {PROJECTLINK}
              </div>
            </div>
          </div>
        </section>
        {BLOCK1}
        {BLOCK2}
        {BLOCK3}
      </Layout>
    )
  }
}

Project.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Project

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      type
      slug
      metaDescription
      color1
      color2
      projectLink
      color3
      color4
      block1Title
      block2Title
      block3Title
      isBlock1Mobile
      isBlock2Mobile
      isBlock3Mobile
      content {
        childMarkdownRemark {
          html
        }
      }
      block1 {
        resolutions(width: 1260) {
          width
          height
          src
        }
        sizes(maxWidth: 1260) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
      block2 {
        resolutions(width: 1260) {
          width
          height
          src
        }
        sizes(maxWidth: 1260) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
      block3 {
        resolutions(width: 1260) {
          width
          height
          src
        }
        sizes(maxWidth: 1260) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
    }
  }
`
